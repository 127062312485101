<template>
  <v-app>
    <!-- 1°first ROW -->
    <div class="row">
      <div class="col-md-12">
        <v-alert type="info" v-if="syncMessage!=''">
              <span v-html="syncMessage"></span>
            </v-alert>
          
        <!-- 1°first Card -->
        <v-card>
          <v-card-title>
            Canal de Venta
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on}">
                <v-btn class="btn-starkoms-primary" dark style="min-width: 138px;" @click="reSyncStock" :disabled="syncDisable">Sincronizar Stock</v-btn>
              </template>
              <span>Actualizar Tabla</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="salesChannel"
              :search="search"
              sort-by="id"
              class="elevation-1 datatable"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top> 
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important;"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="reSyncIds(item.canal)"
                    >mdi-refresh</v-icon>
                  </template>
                  <span>Actualizar Sincronización Identificadores Productos (No es Stock)</span>
                </v-tooltip>
              </template>

              <template v-slot:no-data>
                <v-btn color="#87CEFA" :loading="loading" @click="fetchData()">Cargando ..</v-btn>
              </template>
            </v-data-table>
          </v-card-text>
           
        </v-card>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.card:hover,
.sdw {
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
  color: black;
}

.pointer {
  cursor: pointer;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: {
    store_id: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    dialog: false,
    valid: true,
    url: "",
    loading: false,
    boolean: false,
    salesChannel: [],
    search: "",
    syncDisable: true,
    syncMessage: "",
    headers: [
      // {
      //   text: "ID",
      //   align: "start",
      //   sortable: false,
      //   value: "id",
      // },
      { text: "Canal de Venta", value: "canal" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Canal de Venta", route: "/inventario/saleschannel" },
    ]);
  },
  created() {
    this.initialize();
    this.fetchData();
    this.fetchConfig();
  },
  methods: {
    initialize() {      
    },
    reinitialize() {
      //this.salesChannel = [];
      //this.loading = true;
    },

    fetchData(){
          var vm = this;
          this.axios({
              url: 'admin/stores/default/marketplaces_data',
          }).then( response => {
              vm.marketplaces = [];
              console.log(response.data);
              response.data.forEach(elem => {
                  let marketplace_data = {};

                  vm.salesChannel.push({
                    id: elem.marketplace.id,
                    canal: elem.marketplace.name 
                    });
                  vm.pageCount = Math.ceil(vm.salesChannel.length / 10);
                  // Object.keys(elem.marketplace_data).forEach(key => {
                  //     // console.log('key', key, typeof elem.marketplace_data[key]);
                  //     // console.log('value', elem.marketplace_data[key])
        
                  // });
                  // vm.marketplaces.push({
                  //     activo: elem.activo,
                  //     marketplace: elem.marketplace,
                  //     marketplace_data
                  // })
              });
              
          }).catch( error => {
              console.log(error);
          })
    },
    fetchConfig(){
          var vm = this;
          this.axios({
              url: '/inventory/stock/sync_config',
          }).then( response => {              
              this.syncDisable = response.data.syncDisable;
              if(response.data.syncDisable){
                this.syncMessage = "Se deben esperar hasta el " + this.$moment(response.data.nextSyncTime).format('LLLL') + ". para volver a realizar la sincronización.<br>La última fue realizada por " + response.data.lastSyncUser + " el " + this.$moment(response.data.lastSync).format('LLLL')  + ".<br>Se permite 1 sincronización de stock general cada " + response.data.syncIntervalHours + " horas. Puedes sincronizar productos específicos en la <a style=\"color:#fff; text-decoration:underline\" href=\"#/marketplaces/productos\">página de sincronización unitaria</a>.";
              }
              else{
                this.syncMessage = "Se permite 1 sincronización de stock general cada " + response.data.syncIntervalHours + " horas. Puedes sincronizar productos específicos en la <a style=\"color:#fff; text-decoration:underline\" href=\"#/marketplaces/productos\">página de sincronización unitaria</a>.";
              }
          }).catch( error => {
              console.log(error);
          })
    },
    reSyncIds(mmkp){
      console.log(mmkp);
      var vm = this;
      this.axios({
          url: '/marketplace/products/syncids',
          method: 'POST',
          data: {
              marketplace: mmkp
          }
      }).then( response => {
          vm.$bvToast.toast("Datos actualizados con éxito. Espere unos minutos para ver reflejados los cambios", {
              title: `Exito`,
              variant: 'success',
              solid: true,
              toaster: 'b-toaster-bottom-center'
          });
      }).catch( error => {
          console.log(error);
      });
    },
    reSyncStock(){
      var vm = this;
      this.syncDisable = true;
      this.axios({
          url: '/inventory/sendstock/store',
          method: 'POST',
      }).then( response => {
          if(response.data && response.data.type && response.data.info){
            vm.$bvToast.toast(response.data.info, {
                title: `Información`,
                variant: response.data.type,
                solid: true,
                toaster: 'b-toaster-bottom-center'
            });
          }
          else{
            vm.$bvToast.toast("Datos actualizados con éxito. Espere unos minutos para ver reflejados los cambios", {
                title: `Exito`,
                variant: 'success',
                solid: true,
                toaster: 'b-toaster-bottom-center'
            });
          }    
          vm.fetchConfig();        
      }).catch( error => {
          console.log(error);
      });
    },
      

  },
};
</script>